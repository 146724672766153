import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

const LogoImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "logo/RW_logo_smdelta2.png" }
      ) {
        childImageSharp {
          fixed(width: 650, height: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.placeholderImage.childImageSharp.fixed}
      imgStyle={{ filter: 'brightness(180%)' }}
    />
  )
}

export default LogoImage
