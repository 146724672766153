import { Link } from 'gatsby'
import React, { FC } from 'react'
import styled from 'styled-components'
import CatalogButton from './CatalogButton'

const List = styled.ul`
  position: absolute;
  top: 5px;
  right: 10px;

  list-style: none;
`

const Item = styled.li`
  padding: 5px 10px;
  display: inline-block;
  color: #fff;
  font-size: 1.2em;

  & a {
    color: #fff;
  }

  & a:hover {
    text-decoration: underline;
  }
`

const BrowseDataButton = styled(CatalogButton)`
  position: absolute;
  top: 3.4em;
  right: 1em;
`

interface Props {
  showHome?: boolean
}

const SplashNav: FC<Props> = ({ showHome = false }) => {
  return (
    <div>
      <List>
        {showHome && (
          <>
            <Item>
              <Link to="/">Home</Link>
            </Item>
            <Item>|</Item>
          </>
        )}
        <Item>
          <Link to="/how-it-works/">How it works</Link>
        </Item>
        <Item>|</Item>
        <Item>
          <Link to="/about-us/">About us</Link>
        </Item>
      </List>

      {showHome && <BrowseDataButton label="Browse public data" />}
    </div>
  )
}
export default SplashNav
