/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { BackTop } from 'antd'
import React, { FC, ReactNode } from 'react'
import Header from './header'
import './layout.css'
import './splash.css'

interface Props {
  children?: ReactNode
  showHeader?: boolean
  showHome?: boolean
}

const Layout: FC<Props> = ({ children, showHeader = true, showHome }) => {
  return (
    <>
      {showHeader && <Header showHome={showHome} />}
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <BackTop />
        <main>{children}</main>
        <footer style={{ textAlign: 'center' }}>
          © Research Workspace, {new Date().getFullYear()}
        </footer>
      </div>
    </>
  )
}

export default Layout
