import { Button } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  font-size: 1.1em;

  &&& {
    background-color: #08979c;
    border-color: #006d75;
    color: #eaeaea;
  }

  &&&:hover {
    background-color: #13c2c2;
    border-color: #006d75;
  }
`

export interface Props {
  label: string
  className?: string
}

const CatalogButton: FC<Props> = ({ label, className }) => {
  return (
    <StyledButton
      href="https://catalog.researchworkspace.com"
      className={className}
      type="primary"
      icon="search"
    >
      {label}
    </StyledButton>
  )
}
export default CatalogButton
